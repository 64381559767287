<template>
  <v-card
    :class="{ 'd-flex flex-row': isLargeScreen, '': !isLargeScreen }"
    style=" overflow: hidden;"
  >
    <div
      @click="
        $router.push({
          name: 'MapView',
          params: { regionId: region.id }
        })
      "
      class="overview-map "
      :style="{
        width: isLargeScreen ? '30%' : '100%',
        'max-width': isLargeScreen ? '300px' : ''
      }"
      :id="regionMapId"
    ></div>
    <v-card-title
      :style="{ width: isLargeScreen ? '30%' : '100%' }"
      class="d-flex flex-column align-start"
    >
      <div class="pb-1">
        {{ getRegionName(region) }}
      </div>
      <v-card-subtitle class="pl-0 py-1">
        {{ $t("regionOverview.area") }}: {{ area }} km<sup>2</sup>
      </v-card-subtitle>
      <v-card-subtitle class="pl-0 py-1" v-if="details.dateRange">
        {{ $t("regionOverview.dataRange") }}:
        {{ details.dateRange.first_step.split("T")[0] }}
        {{ $t("regionOverview.to") }}
        {{ details.dateRange.last_step.split("T")[0] }}
      </v-card-subtitle>
    </v-card-title>
    <v-spacer></v-spacer>
    <v-card-actions>
      <div v-for="feature in features.coreFeatures" :key="feature.label">
        <v-btn
          outlined
          rounded
          dense
          small
          class="mx-2"
          v-if="feature.available"
          color="white"
          :style="{
            'background-color':
              feature.route.name == 'Visualize' ? '#40ADD5' : '#2c4186'
          }"
          :to="feature.route"
        >
          {{ $t(`${feature.label}`) }}</v-btn
        >
      </div>
      <features-menu
        v-if="features.addFeatures"
        :features="features.addFeatures"
        :region="region"
        :place="'regionCard'"
      ></features-menu>
    </v-card-actions>
  </v-card>
</template>

<script>
import bbox from "@turf/bbox";
import MapboxMixins from "@/core/mixins/mapbox.mixins";
import geojsonArea from "@mapbox/geojson-area";
import FeaturesMenu from "@/core/components/regionsOverview/FeaturesMenu.vue";
import serviceSelectionMixin from "@/core/mixins/serviceSelection.mixin";
import * as Llet from "leaflet";
import "leaflet/dist/leaflet.css";
import MapboxConfig from "@/core/plugins/mapbox";
import { mapActions, mapState } from "vuex";

export default {
  name: "regionListCard",
  components: { FeaturesMenu },
  mixins: [MapboxMixins, serviceSelectionMixin],
  data: () => ({
    mapLoading: true,
    map: null,
    isLargeScreen: true,
    details: "",
    features: []
  }),
  props: {
    region: Object
  },
  computed: {
    ...mapState("management", ["accessToken"]),
    regionMapId() {
      return `map-${this.region.id}`;
    },
    area() {
      const area = geojsonArea.geometry(this.region.geometry);
      const areaSquare = ((area / 1000000) * 1000) / 1000; // square kilometer
      if (areaSquare > 1) {
        return areaSquare.toFixed(0);
      } else {
        return areaSquare.toFixed();
      }
    },
    boundingBox() {
      if (this.region) {
        return bbox(this.region);
      } else {
        return null;
      }
    }
  },
  methods: {
    ...mapActions("management", ["getOrFetchAccessToken"]),
    getRegionName(feature) {
      let regionName = feature.properties.name;
      if (feature.properties?.country_code) {
        regionName = `${regionName}, ${feature.properties.country_code}`;
      }
      return regionName;
    },
    disableMapInteraction(map, id, cursor) {
      map.dragging.disable();
      map.touchZoom.disable();
      map.doubleClickZoom.disable();
      map.scrollWheelZoom.disable();
      map.boxZoom.disable();
      map.keyboard.disable();
      if (map.tap) map.tap.disable();
      document.getElementById(id).style.cursor = cursor;
    },
    async checkScreenSize() {
      this.isLargeScreen = this.$vuetify.breakpoint.mdAndUp;
    },
    initMap() {
      const aoiBbox = this.boundingBox;
      const padding = 0.1;
      const initMap = Llet.map(this.regionMapId, {
        attributionControl: false,
        zoomControl: false
      }).fitBounds([
        [aoiBbox[1] - padding, aoiBbox[0] - padding],
        [aoiBbox[3] + padding, aoiBbox[2] + padding]
      ]);

      this.disableMapInteraction(initMap, this.regionMapId, "pointer");

      Llet.tileLayer(
        `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${MapboxConfig.accessToken}`
      ).addTo(initMap);

      const geojsonLayer = Llet.geoJSON(this.region, {
        style: {
          color: "#40ADD5",
          weight: 3
        }
      }).addTo(initMap);
      geojsonLayer.on("mouseover", function(event) {
        event.layer.getElement().style.cursor = "pointer";
      });
      return initMap;
    },
    async fetchLayers() {
      const client = this.$appConfig.keycloakClient;
      const response = await this.$rastless.get(
        `/layers?client=${client}&region_id=${this.region.id}&token=${this.accessToken}`
      );
      return response.data;
    },
    async fetchTimesteps(layerId) {
      try {
        const response = await this.$rastless.get(
          `/layers/${layerId}/steps-range?token=${this.accessToken}`
        );
        return response.data;
      } catch {
        return { startDate: " T ", endDate: " T " };
      }
    },
    async getDetails() {
      const layers = await this.fetchLayers();
      const layerNames = [];
      layers.forEach(layer => layerNames.push(layer.title));
      const dateRange = await this.fetchTimesteps(layers[0].layerId);
      return { layers: layers, dateRange: dateRange };
    }
  },
  async mounted() {
    await this.checkScreenSize();
    const mapObject = this.initMap();
    this.map = mapObject;
    this.$emit("loadingFinish");
  },
  created() {
    this.getOrFetchAccessToken().then(async () => {
      this.features = await this.getFeatures(this.region.id, this.region);
      this.details = await this.getDetails();
    });
  }
};
</script>

<style scoped>
.overview-map {
  height: 160px;
  border-radius: 0 !important;
}
</style>
