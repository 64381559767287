<template>
  <v-dialog
    v-model="dialog"
    width="auto"
    max-width="455"
    persistent
    style="z-index: 99"
  >
    <v-card class="mx-auto">
      <v-card
        elevation="0"
        class="white--text align-end"
        height="120px"
        style="background-color: #C5D4EB"
      >
        <v-btn class="icon-check" style="background-color: #1D4180" icon fab>
          <v-icon color="white" large>mdi-information-outline</v-icon>
        </v-btn>
      </v-card>
      <v-card-title class="pt-8  d-flex justify-center align-center">
        {{ $t("welcomeToAqua") }} {{ $appConfig.appName }}!
      </v-card-title>

      <v-card-text class="pt-3 text-center align-center">
        {{ $t("demoRegionInfoText") }}
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn text color="primary" @click="$keycloak.logoutFn">
          {{ $t("logout") }}
        </v-btn>
        <v-spacer />
        <v-btn rounded color="primary" @click="addDemo">
          {{ $t("okay") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";

export default {
  name: "addDemoRoleDialog",
  data: function() {
    return {
      dialog: false
    };
  },
  props: {
    regionFeatures: Array
  },
  methods: {
    async addDemo() {
      const client = this.$appConfig.keycloakClient;
      const roles = this.$keycloak.resourceAccess?.[client]?.roles;
      if (roles === undefined || !roles.includes("demo")) {
        await axios.get(`/management/add-demo-role/`);
      }
      this.reloadPage();
    },

    reloadPage() {
      this.$router.go();
      this.dialog = false;
    },

    goToOrder() {
      this.$router.push("Order");
      this.dialog = false;
    }
  },
  created() {
    if (!this.regionFeatures.length) {
      this.dialog = true;
    }
  }
};
</script>

<style scoped>
.icon-check {
  position: absolute;
  top: 90px;
  left: 195px;
}

.icon-text {
  /* v-text/body-small */
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}

.icon-symbol {
  font-size: 38px;
}
</style>
