<template>
  <v-app>
    <bar v-if="showBar" />
    <router-view
      v-if="$appConfig.keycloakClient === 'coasts'"
      name="coastMenu"
    ></router-view>
    <router-view v-else name="menu"></router-view>
    <router-view :key="$route.fullPath"></router-view>
    <v-footer
      v-if="$keycloak.authenticated"
      app
      color="white"
      inset
      padless
      height="25"
      style="font-size: 12px"
    >
      <div class="pl-1">
        EOMAP GmbH & Co. KG | Schlosshof 4a | D-82229 Seefeld |
        <a
          v-if="client == 'xylem'"
          href="mailto:eoapp@xylem.com, xylem@eomap.de"
          class="px-1"
          style="cursor: pointer; text-decoration: underline"
        >
          eoapp@xylem.com
        </a>
        <a
          v-else
          href="mailto:info@eomap.de"
          class="px-1"
          style="cursor: pointer; text-decoration: underline"
        >
          info@eomap.de </a
        >|
        <a
          id="legal_notice"
          class="px-1"
          style="cursor: pointer; text-decoration: underline"
          href="https://www.eomap.com/legal-information/"
        >
          Legal Notice
        </a>
      </div>
      <div
        style="padding-bottom: 4px"
        v-if="this.$appConfig.keycloakClient === 'hypos'"
      >
        <v-row>
          <v-col cols="2" v-if="roles() && roles().includes('full-access')">
            <v-btn
              height="20"
              width="80"
              color="white"
              elevation="0"
              target="_blank"
              href="https://creativecommons.org/licenses/by-sa/4.0/legalcode"
            >
              <v-img
                height="20"
                contain
                title="HYPOS data are licensed under the Creative Commons Attribution-ShareAlike 4.0 International Public License"
                :src="require('/src/core/assets/hypos/license_BY_SA.png')"
              />
            </v-btn>
          </v-col>
          <v-col cols="2" v-else>
            <v-btn
              height="20"
              width="80"
              color="white"
              elevation="0"
              target="_blank"
              href="https://creativecommons.org/licenses/by-nc-sa/4.0/legalcode"
            >
              <v-img
                height="20"
                contain
                title="HYPOS demonstration data are licensed under the Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International Public License"
                :src="require('/src/core/assets/hypos/license_BY_NC_SA.png')"
              />
            </v-btn>
          </v-col>
          <v-col cols="2" style="padding-right: 0;">
            <v-img
              height="20"
              contain
              :src="require('/src/core/assets/hypos/eu_logo_small.png')"
            />
          </v-col>
          <v-col cols="6" style="padding-left: 0">
            <small
              ><b
                >The HYPOS project was funded under Grant Agreement No.
                870504</b
              ></small
            >
          </v-col>
        </v-row>
      </div>
    </v-footer>
    <v-snackbar
      v-model="snackbarDialog"
      :color="snackColor"
      :timeout="snackTimeout"
      multi-line
      outlined
    >
      {{ snackMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          :color="snackColor"
          text
          v-bind="attrs"
          @click="snackbarDialog = false"
        >
          {{ $t("close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import Bar from "@/core/components/bar/CoreBar";
import { mapState } from "vuex";

export default {
  name: "App",
  components: {
    Bar
  },
  methods: {
    roles() {
      const roles = this.$keycloak.resourceAccess?.hypos?.roles;
      return roles;
    },
    setIconAndTitle() {
      const favicon = document.createElement("link");
      favicon.rel = "icon";
      favicon.type = "image/x-icon";
      if (window.location.origin.includes("aqua")) {
        favicon.type = "image/png";
        favicon.href = "/favicon_aqua.png";
        window.document.title = "eoapp AQUA | Water Quality from Space";
        document
          .getElementById("meta-tag")
          .setAttribute(
            "content",
            "EOMAP's web app to generate and analyse water quality data on inland and coastal waters worldwide."
          );
      } else if (window.location.origin.includes("hypos")) {
        favicon.href = "/favicon.ico";
        window.document.title = "eoapp HYPOS | Hydropower from Space";
        document
          .getElementById("meta-tag")
          .setAttribute(
            "content",
            "EOMAP's web app for environmental analysis designed to support hydropower operations and planning"
          );
      } else if (window.location.origin.includes("xylem")) {
        favicon.type = "image/png";
        favicon.href = "/favicon_aqua.png";
        window.document.title = "eoapp Xylem | Water Quality from Space";
        document
          .getElementById("meta-tag")
          .setAttribute(
            "content",
            "EOMAP's web app to generate and analyse water quality data on inland and coastal waters worldwide."
          );
      } else {
        favicon.href = "/favicon.ico";
        window.document.title = "eoapp | EOMAP ";
        document
          .getElementById("meta-tag")
          .setAttribute(
            "content",
            "EOMAP's web app to generate and analyse water quality data on inland and coastal waters worldwide"
          );
      }
      document.head.appendChild(favicon);
    }
  },
  computed: {
    ...mapState("app", [
      "snackColor",
      "snackMessage",
      "snackTimeout",
      "activeTab"
    ]),
    snackbarDialog: {
      get() {
        return this.$store.state.app.snackDialog;
      },
      set() {
        this.$store.dispatch("app/hideSnackbar");
      }
    },
    client() {
      return this.$appConfig.keycloakClient;
    },
    showBar() {
      return !this.$route.meta?.hideBar;
    }
  },
  created() {
    this.$i18n.locale = this.$appConfig.language;
    this.setIconAndTitle();
  }
};
</script>

<style>
.v-application {
  height: 100vh;
}
</style>
