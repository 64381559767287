<template>
  <section>
    <v-container fluid>
      <v-row class="text-center  justify-space-between">
        <v-col cols="auto">
          <v-img class="mx-15 my-10" :src="imageUrl" width="150"></v-img>
        </v-col>
        <v-col class="align-center d-flex ">
          <p class="my-8 text-h6 font-weight-light">
            This project has received funding from the European Union’s Horizon
            2020 Research and Innovation Programme under Grant Agreement No
            101131527
          </p>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "FundingSection",
  props: {
    client: {},
    contentInfos: {}
  },
  computed: {
    imageUrl() {
      return "/images/bluex/welcomePage/europeanUnion.jpg";
    }
  }
};
</script>

<style scoped>
section {
  padding: 5px 0;
  background-color: white;
}
</style>
