<template>
  <v-card class="pa-2" elevation="0">
    <v-card-title>
      <span class="mt-5 text-h5 grey--text">{{
        $t("contactForm.contactUs")
      }}</span>
    </v-card-title>
    <v-card-text class="pb-1">
      <template>
        <v-row>
          <v-col cols="8">
            {{ $t("contactForm.contactInfo", { appName: $appConfig.appName }) }}
          </v-col>
        </v-row>
        <v-row class="mt-5">
          <v-col cols="6">
            <v-select
              v-model="selectedTopic"
              :items="topics"
              :label="$t('contactForm.topic')"
            >
            </v-select>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row class="pb-0">
          <v-col class="mb-0 pb-0">
            <v-textarea
              v-model="message"
              outlined
              color="teal"
              :label="$t('contactForm.yourMessage')"
            >
            </v-textarea>
          </v-col>
        </v-row>
      </template>
    </v-card-text>
    <v-card-actions>
      <v-row no-gutters align="center" justify="center">
        <v-col>
          {{ $t("contactForm.orJustEmailUs") }}
          <a
            v-if="$appConfig.keycloakClient === 'xylem'"
            href="mailto:eoapp@xylem.com, xylem@eomap.de"
            >eoapp@xylem.com</a
          >
          <a v-else href="mailto:wq@eomap.de">wq@eomap.de</a>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn color="grey" @click="clearEntries">
          {{ $t("contactForm.clear") }}
        </v-btn>
        <v-btn
          depressed
          class="mr-5 ml-5"
          color="primary"
          :disabled="message === '' || selectedTopic === ''"
          right
          @click="sendMessage"
        >
          <v-icon v-if="notificationSent">mdi-email-check-outline</v-icon>
          <span v-else>{{ $t("contactForm.send") }}</span>
          <span v-if="notificationSent">{{
            $t("contactForm.messageSent")
          }}</span>
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import creditMixin from "@/core/mixins/credit.mixin";

export default {
  name: "contactPopup",
  mixins: [creditMixin],
  props: {
    userInfo: Array,
    type: String
  },
  data() {
    return {
      dialog: false,
      message: "",
      selectedTopic: "",
      allInputs: false,
      topics: [
        this.$t("contactForm.monitoring"),
        this.$t("contactForm.download"),
        this.$t("contactForm.insituData"),
        this.$t("contactForm.orderData"),
        this.$t("contactForm.upload"),
        this.$t("contactForm.credits"),
        this.$t("contactForm.otherTopic")
      ],
      notificationSent: false
    };
  },
  methods: {
    ...mapActions("app", ["showSnackbar"]),
    async sendMessage() {
      try {
        await this.postMessage(
          this.userInfo[0],
          this.$appConfig.keycloakClient,
          this.selectedTopic,
          this.message
        );
        this.clearEntries();
        this.notificationSent = true;
        setTimeout(() => {
          this.notificationSent = false;
        }, 3000);
      } catch (error) {
        this.showSnackbar({
          show: true,
          message:
            this.$t("downloadView.processNewDownloadErrorMsg") + `${error}`,
          color: "error"
        });
        this.notificationSent = false;
      }
    },
    clearEntries() {
      this.message = "";
      this.selectedTopic = "";
    }
  }
};
</script>
