<template>
  <section :style="{ 'background-color': contentInfos.color }">
    <v-container>
      <v-row class="justify-center d-flex">
        <v-col
          md="6"
          cols="12"
          class="pr-10"
          style="align-content: center; justify-content: center"
        >
          <div class="align-center justify-center ">
            <p class="text-h4">{{ $t(infosTexts.title) }}</p>
            <p class="my-8 text-h6 font-weight-light">
              {{ $t(infosTexts.text1) }}
            </p>
            <p class="my-8 text-h6 font-weight-light" v-if="infosTexts.text2">
              {{ $t(infosTexts.text2) }}
            </p>
            <p class="my-8 text-h6 font-weight-light" v-if="infosTexts.text3">
              {{ $t(infosTexts.text3) }}
            </p>
          </div>
        </v-col>
        <v-col
          md="6"
          cols="12"
          style="align-content: center; justify-content: center"
        >
          <v-img
            contain
            :src="image"
            alt="circle"
            class="align-center justify-center d-flex"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "WhatWeDoSection",
  props: {
    client: {},
    contentInfos: {}
  },
  computed: {
    infosTexts() {
      return this.contentInfos;
    },
    image() {
      return this.contentInfos.imageUrl;
    }
  }
};
</script>

<style scoped>
section {
  padding: 82px 0;
  background-color: #f0f2f4;
}
</style>
