<template>
  <section>
    <v-container fluid>
      <v-row class="text-center mb-12">
        <v-col class="text-h4">{{ $t(info.heading) }}</v-col>
      </v-row>
      <v-row class="text-center justify-center">
        <div v-for="item in info.icons" :key="item.text">
          <v-img class="mx-15 my-10" :src="item.imageUrl" width="200"></v-img>
        </div>
      </v-row>
    </v-container>
  </section>
</template>
<script>
export default {
  name: "PartnersSection",
  props: {
    client: {},
    contentInfos: {}
  },
  computed: {
    info() {
      return this.contentInfos.partners;
    }
  }
};
</script>

<style scoped>
section {
  padding: 82px 0;
  background-color: "#f0f2f4";
}
</style>
